<template>
  <div>
    <header class="fd-app-mode-header">
      <div
        class="fd-app-mode-header-bg"
        :style="{ backgroundImage: 'url(' + fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      >
        <div class="fd-app-mode-header-overlay">
          <div class="container">
            <div class="fd-app-mode-header-overlay-content">
              <div class="fd-app-mode-header-overlay-content-title">
                <h1>Meu carrinho</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="fd-app-wrap">
      <div class="container">
        <div class="fd-carrinho">
          <div class="fd-carrinho-content">
            <ul
              id="divMeuCarrinho"
              class="fd-carrinho-content-list"
            >
              <h2 class="mx-auto">
                {{ $store.state.fastCarrinho.length }} curso(s) no carrinho
              </h2>
              <ul class="col-12 list-unstyled m-0">
                <li
                  v-for="curso in $store.state.fastCarrinho"
                  :key="curso.id_curso"
                  class="fd-carrinho-content-list-item mt-2"
                >
                  <div class="fd-carrinho-content-list-item-actions">
                    <div
                      class="fd-carrinho-content-list-item-actions-remove"
                      style="curso: pointer"
                      @click="removeItemCarrinho(curso)"
                    >
                      X
                    </div>
                  </div>
                  <div
                    v-if="curso.logo_curso"
                    class="fd-carrinho-content-list-item-image"
                  >
                    <img
                      class="img-fluid"
                      style="max-height: 100%"
                      :src="curso.logo_curso"
                      alt
                    >
                  </div>
                  <div
                    v-else
                    v-lazy:background-image="require('@/assets/images/app/plataforma-img.jpg')"
                    class="fd-carrinho-content-list-item-image"
                  />
                  <div class="fd-carrinho-content-list-item-info">
                    <a
                      :href="'/loja-virtual/' + $route.params.id_plataforma + '/curso/' + curso.id_curso"
                      style="text-decoration:none;"
                    >
                      <h2 class="mb-0">{{ curso.nome_curso }}</h2>
                      <p>
                        {{ curso.detalhe_curso }}
                        <br>
                      </p>
                      <strong>
                        <h2
                          v-if="!curso.id_item_cobranca"
                          class="text-warning"
                        >(SOB DEMANDA)</h2>
                        <h2 v-else>R$ {{ formataPreco(curso.valor_item) }}</h2>
                        <div class="fd-carrinho-content-list-item-info-professor d-none">
                          <div
                            id="imgImagemTutorCurso_1215"
                            class="fd-carrinho-content-list-item-info-professor-avatar"
                            style="background-image: url('https://toussaint.concurseiro.com/css/images/avatar.svg')"
                          />
                          <div class="fd-carrinho-content-list-item-info-professor-info">
                            <span>TUTOR</span>
                            <p id="txtNomeTutorCurso_1215" />
                          </div>
                        </div>
                      </strong>
                    </a>
                  </div>
                </li>
              </ul>
            </ul>
          </div>
          <div class="fd-carrinho-total">
            <h2>Total</h2>

            <div class="fd-carrinho-total-price pt-4">
              <strong v-if="$store.state.fastCarrinho.length && produtosComPreco">
                <h1 class="font-weight-bold">{{ precoTotal.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" }) }}</h1>
              </strong>
              <strong v-else-if="$store.state.fastCarrinho.length && !produtosComPreco">
                <span>Solicitar</span>
              </strong>
              <strong v-else>
                <span>Carrinho vazio</span>
              </strong>
              <!--
                        <strong><span>R$</span>48,<small>99</small></strong>
                        <small class="mt-4">R$ 400,00</small>
              -->
            </div>
            <div class="fd-carrinho-total-actions mt-1">
              <p class="fd-carrinho-total-actions-desconto ml-3">
                <!--
                            R$ 351,09 de desconto
                -->
              </p>
              <div class="fd-carrinho-total-actions-options">
                <div class="col-sm-12 col-md-12 col-lg-12 mt-4 text-danger">
                  <p id="msgErroCompra" />
                </div>
                <a
                  v-if="fastProdutosCompra.length && !fastProdutosSolicitacao.length"
                  href="#"
                  class="btn btn-primary btn-curso-sobdemand"
                  @click.prevent="acessarCheckout()"
                >Comprar</a>
                <a
                  v-else-if="!fastProdutosCompra.length && fastProdutosSolicitacao.length"
                  href="#"
                  class="btn btn-primary btn-curso-sobdemand"
                  @click.prevent="solicitarOrcamento()"
                >Solicitar orçamento</a>
                <a
                  v-else-if="fastProdutosCompra.length && fastProdutosSolicitacao.length"
                  href="#"
                  class="btn btn-primary btn-curso-sobdemand"
                  @click.prevent="solicitarOrcamento()"
                >Solicitar e comprar</a>

                <a
                  :href="'/loja-virtual/' + $route.params.id_plataforma"
                  class="ml-3"
                >Continuar comprando</a>
              </div>
              <div
                v-if="$store.state.fastCarrinho.length"
                class="fd-carrinho-total-actions-cupom d-none"
              >
                <input
                  id="txtCupomDesconto"
                  type="text"
                  placeholder="Cupom de desconto..."
                >
                <a
                  href="#"
                  class="btn btn-info-light"
                  onclick="validaCupomDesconto()"
                >Aplicar</a>
                <p
                  id="txtCupomDescontoError"
                  class="text-danger"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCadastro"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="login-page">
        <div class="form">
          <div class="pl-4 pr-4 pb-4">
            <img
              v-lazy="require('@/assets/images/logo_horizontal.png')"
              style="max-width: 50%; height: auto"
            >
          </div>
          <form
            id="fast-form-login"
            class="login-form"
            @submit.prevent="fastCadastrar"
          >
            <input
              v-model="formCadastro.nome_usuario"
              type="text"
              placeholder="Nome completo"
            >
            <input
              v-model="formCadastro.email"
              type="text"
              placeholder="Email"
            >
            <input
              v-model="formCadastro.senha1"
              type="password"
              placeholder="Senha"
              minlength="6"
            >
            <input
              v-model="formCadastro.senha2"
              type="password"
              placeholder="Confirmar senha"
              minlength="6"
            >
            <button type="submit">
              Cadastrar
            </button>
            <p class="message">
              Já é cadastrado?
              <a :href="'?login&redirect=loja-virtual/' + $route.params.id_plataforma + '/carrinho'">Clique para logar</a>
            </p>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import headerCursoInfo from "@/assets/images/header-curso-info.jpg";

export default {
  name: "LojaVirtualCarrinho",
  mixins: [methods],
  data: function() {
    return {
      headerCursoInfo,
      usuarioLogado: false,
      fastPlataforma: {
        banner_plataforma: "",
        id_plataforma: 0,
        logo_plataforma: "",
        nome_plataforma: "",
        url_plataforma: "",
      },
      fastCurso: [],
      // Informações do carrinho
      fastProdutosCompra: [],
      fastProdutosSolicitacao: [],
      produtosComPreco: true,
      precoTotal: 0,
      // Cadastro
      formCadastro: {
        nome_usuario: "",
        email: "",
        senha1: "",
        senha2: "",
      },
    };
  },
  mounted() {
    if (this.$route.params.id_plataforma) {
      this.$store.state.fastPermissoes.id_plataforma = this.$route.params.id_plataforma;
      this.validaSessao() ? (this.usuarioLogado = true) : (this.usuarioLogado = false);

      if (this.getUrlParameter("course_code")) {
        this.getFastCurso(atob(this.getUrlParameter("course_code")));
      }
      if (this.getUrlParameter("promo_code")) {
        this.getFastPromo(atob(this.getUrlParameter("promo_code")));
      } else {
        this.getPlataformaData();
      }

      
    }
  },
  methods: {
    async getPlataformaData() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma/lista_detalhes_cliente?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataforma = obj[0];
        }

        this.produtosComPreco = true;
        this.fastProdutosSolicitacao = [];
        this.fastProdutosCompra = [];
        this.precoTotal = 0;
        // Verifica se os produtos possuem preço
        if (this.$store.state.fastCarrinho.length) {
          this.$store.state.fastCarrinho.forEach((c) => {
            this.fastProdutosCompra.push(c);
            this.precoTotal += c.valor_item
            /*
            if (!c.preco) {
              this.produtosComPreco = false;
              this.fastProdutosSolicitacao.push(c);
            } else {
              this.fastProdutosCompra.push(c);
              
              if (c.preco.indexOf(",") == "-1") {
                this.precoTotal += parseFloat(                
                  c.preco
                    .split(" ")[1]                
                );
              } else {
                this.precoTotal += parseFloat(
                this.formatarMoeda(
                  c.preco
                    .split(" ")[1]
                    .replace(".", "")
                    .replace(",", ".")
                )
              );
              }
            }
            */
          });
          /*
          console.log("produtosComPreco", this.produtosComPreco);
          console.log(
            "fastProdutosSolicitacao",
            this.fastProdutosSolicitacao.length
          );
          console.log("fastProdutosCompra", this.fastProdutosCompra.length);
          console.log("precoTotal", this.precoTotal);*/
        }

        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    formatarMoeda(elemento) {
      let value = elemento.toString();
      if (value.indexOf(".") !== -1 && value.indexOf(",") !== -1) {
        if (value.indexOf(".") < value.indexOf(",")) {
          //inglês
          return parseFloat(value.replace(/,/gi, ""));
        } else {
          //português
          return parseFloat(value.replace(/./gi, "").replace(/,/gi, "."));
        }
      } else {
        return parseFloat(value);
      }
    },
    removeItemCarrinho(curso) {
      if (this.$store.state.fastCarrinho.length) {
        this.$store.state.fastCarrinho.forEach((p, index) => {
          if (curso.id_curso == p.id_curso) {
            this.$store.state.fastCarrinho.splice(index, 1);
          }
        });
        this.setFastSessao(settings.fastSessaoCarrinho, this.$store.state.fastCarrinho);
      }
      this.getPlataformaData();
    },
    acessarCheckout() {
      window.location.href = "/loja-virtual/" + this.$route.params.id_plataforma + "/checkout";
    },
    async solicitarOrcamento() {
      if (!this.usuarioLogado) {
        this.showModal("modalCadastro");
      } else {
        let fast_requisicao_compra = {};
        let fast_requisicao_compra_item = {
          quantidade: 1,
        };
        let fast_ids_cursos_venda = [];
        let fast_ids_produtos_vindi = [];
        let fast_ids_cursos_ondemand = [];
        let arrayIds = this.fastProdutosSolicitacao.map((c) => {
          if (!c.preco) return c.id_curso;
        });
        let fast_ids_cursos = {
          ids_cursos: arrayIds.join(),
        };

        let model = {
          fast_requisicao_compra: fast_requisicao_compra,
          fast_requisicao_compra_item: fast_requisicao_compra_item,
          fast_ids_cursos: fast_ids_cursos,
        };
        this.$store.state.fastCarregando = true;
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_requisicao_compra/insere", this.fastAjaxOptions("POST", JSON.stringify(model)));
          let json = await resp.json();
          console.log(json);
          this.$store.state.fastCarregando = false;
          this.$toast.open({
            message: "Orçamento solicitado com sucesso!",
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });

          if (this.fastProdutosCompra.length) {
            if (this.$store.state.fastCarrinho.length) {
              this.$store.state.fastCarrinho.forEach((p, index) => {
                if (!p.preco) {
                  this.$store.state.fastCarrinho.splice(index, 1);
                }
              });
              this.setFastSessao(settings.fastSessaoCarrinho, this.$store.state.fastCarrinho);
            }

            this.$toast.open({
              message: "Redirecionando para o checkout...",
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            setTimeout(function() {
              window.location.href = "/loja-virtual/" + this.$route.params.id_plataforma + "/checkout";
            }, 3000);
          } else {
            this.$store.state.fastCarrinho = [];
            this.setFastSessao(settings.fastSessaoCarrinho, null);

            setTimeout(function() {
              location.reload();
            }, 3000);
          }
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    async fastCadastrar() {
      if (this.formCadastro.nome_usuario.split(" ").length < 2) {
        this.$toast.open({
          message: "Insira seu nome e sobrenome",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      } else if (this.formCadastro.senha1 != this.formCadastro.senha2) {
        this.$toast.open({
          message: "As senhas digitadas não coincidem",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      } else {
        this.$store.state.fastCarregando = true;
        this.verificaEmailConcurseiro(this.formCadastro.email)
          .then(async () => {
            const First_name = this.formCadastro.nome_usuario.split(" ")[0];
            const Last_name = this.formCadastro.nome_usuario
              .split(" ")
              .slice(1)
              .join(" ");
            const Usuario = {
              First_name: First_name,
              Last_name: Last_name,
              Password: btoa(this.formCadastro.senha1),
              Email: this.formCadastro.email,
            };
            console.log("Usuario", Usuario);
            // Cadastra pessoa
            this.cadastraUsuarioConcurseiro(Usuario)
              .then((e) => {
                sessionStorage.setItem(settings.fastSessaoUsuario, e);
                sessionStorage.setItem(settings.fastSessaoToken, JSON.stringify({ access_token: JSON.parse(e).token }));
                this.this.usuarioLogado = true;
                this.solicitarOrcamento();
                this.$store.state.fastCarregando = false;
                //location.reload();
              })
              .catch((e) => {
                // loading
                this.$store.state.fastCarregando = false;
                console.log(e);
              });
          })
          .catch((e) => {
            // loading
            this.$store.state.fastCarregando = false;
            console.log(e);
          });
      }
    },
    async verificaEmailConcurseiro(email) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiConcurseiro + "api/questoes/verificaEmail?email=" + email, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          if (json.length) {
            this.$toast.open({
              message: "Email já cadastrado!",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("Email já cadastrado");
          } else {
            console.log("Email ok... " + email);
            resolve(true);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    async cadastraUsuarioConcurseiro(Usuario) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/register", this.fastAjaxOptions("POST", JSON.stringify(Usuario)));
          let json = await resp.json();
          console.log("cadastraUsuarioConcurseiro", json);
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    // Carrinho
    adicionaCarrinho(curso) {
      if (this.$store.state.fastCarrinho.length) {
        let incluido = false;
        this.$store.state.fastCarrinho.forEach((p) => {
          if (curso.id_curso == p.id_curso) incluido = true;
        });
        if (!incluido) {
          this.$store.state.fastCarrinho.push(curso);
          curso.carrinho = true;
        }
      } else {
        this.$store.state.fastCarrinho.push(curso);
        curso.carrinho = true;
      }
      this.setFastSessao(settings.fastSessaoCarrinho, this.$store.state.fastCarrinho);

      this.getPlataformaData();
    },
    async getFastCurso(id_curso) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso/lista_detalhes_ondemand?id_plataforma=" + this.$route.params.id_plataforma + "&id_curso=" + id_curso, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj[0].preco = "";
          let incluidoCarrinho = false;
          if (this.$store.state.fastCarrinho.length) {
            this.$store.state.fastCarrinho.forEach((p) => {
              if (obj[0].id_curso == p.id_curso) incluidoCarrinho = true;
            });
          }
          obj[0].carrinho = incluidoCarrinho;

          if (obj[0].id_produto_vindi) this.getCursoPrecoVindi(obj[0]);

          this.fastCurso = obj;
        } else {
          this.fastCurso = [];
        }
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    async getCursoPrecoVindi(curso) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso/lista_produto_vindi?id_produto_vindi=" + curso.id_produto_vindi, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        if (JSON.parse(json).product) {
          this.fastCurso.forEach((e) => {
            if (e.id_curso == curso.id_curso) {
              e.preco = JSON.parse(json).product.pricing_schema.short_format;
              this.adicionaCarrinho(curso);
            }
          });
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastPromo(id_promo) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_loja_promo/lista?id_promo=" + id_promo + "&id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        console.log("getFastPromo", json);
        let obj = Array.from(json);
        if (obj.length) {
          this.fastCurso = [];
          obj.forEach((obj) => {
            obj.preco = "R$ " + obj.valor_avista / json.length;
            obj.promo_code = id_promo;
            let incluidoCarrinho = false;
            if (this.$store.state.fastCarrinho.length) {
              this.$store.state.fastCarrinho.forEach((p) => {
                if (obj.id_curso == p.id_curso) incluidoCarrinho = true;
              });
            }
            obj.carrinho = incluidoCarrinho;

            this.adicionaCarrinho(obj);

            this.fastCurso.push(obj);
          });
        } else {
          this.fastCurso = [];
        }
        this.$store.state.fastCarregando = false;
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style scoped>
.bg-image-none {
  background-image: none;
}
.login-page {
  width: 100%;
  padding: 0;
  margin: auto;
}
.login-page .form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  max-width: 100%;
  margin: auto;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.login-page .form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.login-page .form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #f18938;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.login-page .form button:hover,
.login-page .form button:active,
.login-page .form button:focus {
  background: #f18938;
}
.login-page .form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.login-page .form .message a {
  color: #f18938;
  text-decoration: none;
}
.login-page .form .register-form {
  display: none;
}
</style>
